import React, { useEffect } from 'react';
import useToken from './useToken';

export default function Logout() {
    return (<div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4"><LogoutPage /></div>
        <div className="col-lg-4"></div>
    </div>);
}

function LogoutPage() {
    const { clearToken } = useToken();
    useEffect(() => {
        clearToken()
        sessionStorage.removeItem("token")
        window.location.href = "/";
    }, [clearToken])

    return (<div className='bs-docs-section'>
        You will be logged out soon
    </div>
    );
}