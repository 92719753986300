import React from "react";
import CurrentDuration from "./CurrentDuration";
import CurrentResponseCode from "./CurrentResponse";

export default function CurrentStatus() {
    return (<>
        <div className="row">
            <div className="col-lg-12">
                <div className="card mb-3">
                    <h3 className="card-header">Current Site Status</h3>
                </div>
            </div>
        </div>

        <div className="row mb-5">
            <div className="col-lg-6">
                <CurrentDuration></CurrentDuration>
            </div>

            <div className="col-lg-6">
                <CurrentResponseCode></CurrentResponseCode>
            </div>
        </div>
    </>
    );
}
