import { NavLink } from "react-router-dom";

import useToken from './useToken';

export default function NavBar() {
  const { token } = useToken();

  return (
    <div className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
      <div className="container">
        <NavLink to="/" className="navbar-brand">PingChck</NavLink>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="isItUp" className="nav-link">Is Site Up?</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="https://github.com/Shadi/srstatus/issues" className="nav-link">GitHub</NavLink>
            </li>
          </ul>

          <ul className="navbar-nav ms-md-auto">
            <ProfileLinks userToken={token} />
          </ul>
        </div>
      </div>
    </div>)
}

function ProfileLinks({ userToken }) {
  if (userToken) {
    return (<>
      <li className="nav-item">
        <NavLink to="profile" className="nav-link">Profile</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="logout" className="nav-link">Log Out</NavLink>
      </li>
    </>)
  } else {
    return (<>
      <li className="nav-item">
        <NavLink to="register" className="nav-link">Register</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="login" className="nav-link">Login</NavLink>
      </li>
    </>)
  }
}
