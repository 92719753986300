import React, { useState, useEffect } from "react";
import { fetchData } from "./utils";
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment'
import useToken from './useToken';


export default function Details() {

    const [durations, setDurations] = useState([]);
    const { token } = useToken();
    useEffect(() => {
        fetchData('/api/metrics/detailedDurations?url=https://localhost.com', token, setDurations);
    }, [token]);


    return (
        <>
            <div className='bs-docs-section'>
                <div className="page-header">
                    <h4 id="navs">Breakdown of Requests Duration</h4>
                </div>
                <DetailedChart chartData={durations} />
            </div>
        </>
    );
}


function DetailedChart({ chartData }) {
    return (
        <ResponsiveContainer height={400} width={'100%'}>
            <AreaChart
                data={chartData.durationsViews}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="4 4" />
                <XAxis type="number" dataKey="time" tickFormatter={(unixTime) => moment(unixTime * 1000).format('DD-MMM-YY  HH:mm')} 
                    domain={[moment(chartData.start * 1000).format('DD-MMM-YY  HH:mm'), moment(chartData.end * 1000).format('DD-MMM-YY  HH:mm')]} />
                <YAxis dataKey="total" domain={[0,2]}/>
                <Tooltip labelFormatter={time => { return "time: " + moment(time * 1000).format('HH:mm'); }} />
                <Legend label="time(seconds)" />
                <Area type="monotone" label="firstByte" name="firstByte" dataKey="firstByte" stroke="#c182ca" fill="#c182ca" activeDot={{ r: 8 }} dot={false} />
                <Area type="monotone" label="tls" name="tls" dataKey="tls" stroke="#82ca9d" fill="#82ca9d" activeDot={{ r: 8 }} dot={false} />
                <Area type="monotone" label="connect" name="connect" dataKey="connect" stroke="#828bca" fill="#828bca" activeDot={{ r: 8 }} dot={false}/>
                <Area type="monotone" label="dns" name="dns" dataKey="dns" stroke="#ffc658" fill="#ffc658" activeDot={{ r: 8 }} dot={false} />
            </AreaChart>
        </ResponsiveContainer>
    );
}