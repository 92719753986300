import React from "react";
import RecentDurations from "./RecentDurations";
import RecentStatus from "./RecentStatus";

export default function RecentMetrics() {

    return (<>

        <div className="row">
            <div className="col-lg-12">
                <div className="card mb-3">
                    <h3 className="card-header">Recent Performance</h3>
                </div>
            </div>
        </div>

        <div className="row mb-5">
            <div className="col-lg-6">
                <RecentDurations></RecentDurations>
            </div>

            <div className="col-lg-6">
                <RecentStatus></RecentStatus>
            </div>
        </div>
    </>);
}
