import React from "react";
import curr_resp from './current_resp.png'
import curr_dur from './current_dur.png'
import recentDurImg from './recent_dur.png'
import recentStatusImg from './recent_status.png'
import detailImg from './detailed.png'
import alerts from './alerts.png'
import {NavLink} from "react-router-dom";


export default function Home() {
    return (<>
        <div className="row">
            <div className="col-lg-12">
                <div className='bs-docs-section'>
                    <p className="alert alert-dismissible alert-secondary lead"><NavLink to="/register" className="alert-link">Register now</NavLink> and get simple statistics about your service performance as seen by End-Users</p>
                </div>

                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <p className="mb-0"></p>
                    </div>
                    <div className="col-lg-4"></div>
                </div>

                <div className="bs-docs-section">
                    <div className="row">
                        <h4>See How your site is doing in real-time: </h4>
                        <div className="col-lg-6">
                            <img src={curr_dur} width={'95%'} alt="current durations"/>
                        </div>
                        <div className="col-lg-6">
                            <img src={curr_resp} width={'95%'} alt="current response"/>
                        </div>

                    </div>
                </div>

                <div className="bs-docs-section">
                    <div className="bs-component">
                        <img src={alerts} width={'95%'} alt="current alerts"/>
                    </div>
                </div>

                <div className="bs-docs-section">
                    <div className="row">
                        <h4>How it was doing during the last few hours: </h4>
                        <div className="col-lg-6">
                            <img src={recentDurImg} width={'95%'} alt="recent durations"/>
                        </div>
                        <div className="col-lg-6">
                            <img src={recentStatusImg} width={'95%'} alt="recent site status"/>
                        </div>

                    </div>
                </div>

                <div className="bs-docs-section">
                    <div className="bs-component">
                        <h4>And how it performed during the last week: </h4>
                        <img src={detailImg} width={'95%'} alt="response time details"/>
                    </div>
                </div>

            </div>
        </div>
    </>)
}