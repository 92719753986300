import React from 'react';
import './App.css';
import Home from './Home';
import RecentAlerts from './RecentAlerts';
import RecentMetrics from './RecentMetrics';
import './Status.js';
import CurrentStatus from './Status.js';
import useToken from './useToken';

function App() {
  const { token } = useToken();

  if(!token) {
    return <Home />
  }

  return (
    <>
      <div className='bs-docs-section'>
        <RecentAlerts></RecentAlerts>
        <CurrentStatus></CurrentStatus>
        <RecentMetrics />
      </div>
    </>
  );
}

export default App;
