import { useEffect, useRef } from "react";
import { Oval } from 'react-loader-spinner'

export function FetchAndUpdate(url, token, callback, interval) {
    useEffect(() => {
        fetchData(url, token, callback);
    }, [url, token, callback]);
    UpdateWithInterval(() =>
        fetchData(url, token, callback), interval);
}


export function fetchData(url, token, dataCallback, ...callbacks) {
    fetch(url, { headers: { Authorization: 'Bearer ' + token } })
        .then(function (response) {
            if (response && !response.ok) {
                throw new Error(response.status)
            }
            return response;
        })
        .then(response => handleAuth(response))
        .then((response) => response.json())
        .then((data) => {
            dataCallback(data);
            callbacks.forEach(callback => callback())
        })
        .catch((err) => {
            console.log("received error response: " + err.message);
        })
}

export function UpdateWithInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function handleAuth(response) {
    if (response.status === 401 || response.status === 403) {
        window.location.href = "/";
    }
    return response;
}

export function LoadingOval() {
    return (
        <div className="row">
            <p className="text-success">We are still collecting data, please wait a few minutes.</p>
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
                <div className="col-lg-4"></div>
                <Oval
                    height={80}
                    width={80}
                    color="#82CA9D"
                    wrapperStyle={{}}
                    wrapperClass="col-lg-4"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#4caf50"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
                <div className="col-lg-4"></div>
                
            </div>
            <div className="col-lg-4"></div>
        </div>
    );
}