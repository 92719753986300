import React, { useState } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment'
import useToken from './useToken';
import { FetchAndUpdate, LoadingOval } from "./utils";
import { NavLink } from "react-router-dom";

export default function RecentDurations() {
    const [durations, setDurations] = useState([]);
    const { token } = useToken();


    FetchAndUpdate('/api/metrics/recentDuration?url=https://localhost', token, setDurations, 120000)


    return (<RecentChart chartData={durations} />)
}

function RecentChart({ chartData }) {
    if (!chartData || !chartData.instants || chartData?.instants?.length < 2) {
        console.log("too little data")
        return <LoadingOval />
    }
    return (
        <>
            <ResponsiveContainer height={300} width={'100%'}>
                <LineChart
                    data={chartData.instants}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" dataKey="time" tickFormatter={(unixTime) => moment(unixTime * 1000).format('HH:mm')}
                        domain={[moment(chartData.start * 1000).format('DD-MMM-YY  HH:mm'), moment(chartData.end * 1000).format('DD-MMM-YY  HH:mm')]} />
                    <YAxis dataKey="value" />
                    <Tooltip labelFormatter={time => { return "time: " + moment(time * 1000).format('HH:mm'); }} />
                    <Legend label="Duration(Seconds)" />
                    <Line type="monotone" label="Duration(Seconds)" name="Duration(Seconds)" dataKey="value" stroke="#82ca9d" activeDot={{ r: 10 }} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            <div className="d-grid gap-2">
                <div className="alert alert-dismissible alert-light">
                    <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
                    See <NavLink to="/details" className="alert-link">a more detailed view</NavLink> of your service performance.
                </div>
            </div>
        </>

    );
}