
import React, { useState } from 'react';
import useToken from './useToken';
import { ThreeDots } from 'react-loader-spinner'

export default function Login() {
    const [formError, setFormError] = useState('');
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const { setToken } = useToken();

    const handleSubmit = async e => {
        e.preventDefault();
        if (email == null || email.trim() === '' || password == null || password.trim() === '') {
            setFormError("Invalid Input")
            return;
        }
        setLoading(true)
        const resp = await loginUser({
            email,
            password
        });
        if (resp.error) {
            setLoading(false)
            setFormError("Invalid Credentials")
        } else if (resp.token) {
            setToken(resp.token, resp.expiry)
            window.location.href = "/";
        }
    }

    return (
        <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
                {loading ? <Loading /> :

                    <form onSubmit={handleSubmit}>
                        {formError ? (
                            <div className="alert alert-dismissible alert-danger">
                                {formError}
                            </div>
                        ) : ""}
                        <fieldset>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1" className="form-label mt-4">Email address</label>
                                <input type="email" name="email" onChange={e => setEmail(e.target.value)} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1" className="form-label mt-4">Password</label>
                                <input type="password" name="password" onChange={e => setPassword(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Password" />
                            </div>

                            <button type="submit" className="btn btn-primary">Log in</button>
                        </fieldset>
                    </form>
                }

            </div>
            <div className="col-lg-4"></div>
        </div>
    );
}

async function loginUser(credentials) {
    return fetch("/api/users/login", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function Loading() {
    return (
        <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4caf50"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName="center-block"
            visible={true}
        />
    );
}
