import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import useToken from './useToken';

export default function IsSiteUp() {

    const { token } = useToken();

    return (<div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4">
            <div className="row">
                <div className='bs-docs-section'>
                    <IsUpForm />
                    <SignUpLink userToken={token} />
                </div>
            </div>
        </div>
        <div className="col-lg-4"></div>
    </div>);
}

function IsUpForm(setResponseCode) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');


    const onSubmit = (data, e) => { CheckService(data, e, setFormError, setFormSuccess) }

    return (<form onSubmit={handleSubmit(onSubmit)}>


        {formSuccess ? (
            <div>
                {formSuccess >= 200 && formSuccess < 400 ? <SiteUpMessage /> : <SiteDownMessage />}
                <div className={formSuccess >= 200 && formSuccess < 400 ? "card text-white bg-success mb-3" : "card text-white bg-danger mb-3"}>
                    <div className="card-header">Current Response Code</div>
                    <div className="card-body">
                        <h4 className="card-title">{formSuccess}</h4>
                    </div>
                </div>
            </div>

        ) : ""}

        {formError ? (
            <div className="alert alert-dismissible alert-danger">
                {formError}
            </div>
        ) : ""}
        <fieldset>
            <div className="form-group">
                <label className="col-form-label mt-4" htmlFor="inputDefault">Full Service Url</label>
                <input type="url" name="url" className="form-control" placeholder="https://example.com" id="inputDefault" aria-invalid={errors.url ? "true" : "false"} {...register("url", { required: true, pattern: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?' })} />
                {errors.url?.type === 'required' && <p className='text-danger'>Enter a complete valid url</p>}
            </div>

            <div className="row">
                <button type="submit" className="btn btn-primary gap-2">Check!</button>
            </div>
        </fieldset>
    </form>
    );
}

function CheckService(data, e, formErrorCallback, successCallback) {
    e.preventDefault();
    const formData = new FormData(e.target);
    fetch("/api/isOnline?url=" + formData.get('url'), {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    }).then((response) => response.json())
        .then((data) => {
            if (data.error) {
                successCallback(null)
                formErrorCallback(data.error)
            } else {
                formErrorCallback(null)
                successCallback(data.success)
            }
        });
}

function SignUpLink({ userToken }) {
    if (userToken) {
        return (<>
        </>)
    } else {
        return (<>
            <div className="row">
                <div className='bs-docs-section'>
                    <p className="alert alert-dismissible alert-secondary lead"><NavLink to="/register" className="alert-link">Register now</NavLink> and keep track of your site status.</p>
                </div>
            </div>
        </>)
    }
}


function SiteUpMessage() {
    return <p class="text-success">The Website is Up and Reachable.</p>
}

function SiteDownMessage() {
    return <p class="text-danger">There is a problem reaching the website.</p>
}
