import React, { useState } from "react";
import useToken from './useToken';
import { FetchAndUpdate } from "./utils";
import moment from 'moment'

export default function RecentAlerts() {
    const [alerts, setAlerts] = useState([]);
    const { token } = useToken();

    FetchAndUpdate('/api/alerts/recentAlerts?url=https://localhost', token, setAlerts, 15000)

    if (alerts.length === 0) {
        return (<div></div>)
    } else {
        return (
            <div className='bs-docs-section'>
                <div className="card mb-3">
                    <h3 className="card-header">Recent Alerts</h3>
                </div>
                {alerts.map(function (d, idx) {
                    return (
                        <div key={idx} className={d.critical ? "alert alert-dismissible alert-danger" : "alert alert-dismissible alert-warning"} >
                            <p className="mb-0">{moment.unix(d.startAt/1000).format("DD-MMM-YYYY HH:mm")} {d.description}.</p>
                        </div>
                    )
                })}


            </div>
        )
    }
}