import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NavBar from './navbar';
import Banner from './banner';
import Details from './Details.js'
import {Route,Routes,HashRouter} from "react-router-dom";
import Registration from './Register';
import Login from './Login';
import Verify from './Verify'
import Logout from './logout';
import Profile from './Profile';
import IsSiteUp from './IsUp';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <CookiesProvider> */}
      <HashRouter>
        <NavBar />
        <div className="container">
          <Banner />
          <Routes>
            <Route exact path="/" element={<App />} />
            <Route path="/details" element={<Details />} />
            <Route path='/register' element={<Registration />} />
            <Route path='/login' element={<Login />} />
            <Route path='/verify' element={<Verify />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/isItUp' element={<IsSiteUp />} />
          </Routes>
        </div>
      </HashRouter>
    {/* </CookiesProvider> */}
  </React.StrictMode >
);

reportWebVitals();
