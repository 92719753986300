import React, { useState } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment'
import useToken from './useToken';
import { FetchAndUpdate, LoadingOval } from "./utils";

export default function RecentStatus() {
    const [ups, setUps] = useState([]);
    const { token } = useToken();

    FetchAndUpdate('/api/metrics/recentStatus?url=https://localhost', token, setUps, 120000)

    return (<RecentChart chartData={ups} measured="Site Up Status" />)

}


function RecentChart({ chartData, measured }) {
    if (!chartData || !chartData.instants || chartData?.instants?.length < 2) {
        return <LoadingOval />
    }

    const CustomizedDot = (props) => {
        const { cx, cy, r, stroke, payload, value, categoryColors } = props;
        const color = '#920000'
     
        if (value < 1) {
        return (
           <circle cx={cx} cy={cy} r={r} stroke={color} strokeWidth={1} fill={color} />
        ); }
        else {
            return null
        }
     };

    return (
        <ResponsiveContainer height={300} width={'100%'}>
            <LineChart
                data={chartData.instants}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" dataKey="time" tickFormatter={(unixTime) => moment(unixTime * 1000).format('HH:mm')} 
                    domain={[moment(chartData.start * 1000).format('DD-MMM-YY  HH:mm'), moment(chartData.end * 1000).format('DD-MMM-YY  HH:mm')]} />
                <YAxis dataKey="value" domain={[0,1]}/>
                <Tooltip labelFormatter={time => { return "time: " + moment(time * 1000).format('HH:mm'); }} />
                <Legend label={measured} />
                <Line type="monotone" label={measured} name={measured} dataKey="value" stroke="#82ca9d" activeDot={{ r: 10 }} dot={<CustomizedDot />} />
            </LineChart>
        </ResponsiveContainer>
    );
}