import React, { useState, useEffect } from "react";
import { fetchData } from "./utils";
import useToken from './useToken';

export default function Profile() {
    return (<div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4"><ProfilePage /></div>
        <div className="col-lg-4"></div>
    </div>);
}

function ProfilePage() {
    const { token } = useToken();
    const [userProfile, setUserProfiles] = useState([]);
    useEffect(() => {
        fetchData('/api/users/profile', token, setUserProfiles);
    }, [token]);

    return (<div className='bs-docs-section'>
        <div className="page-header"><h4 id="navs">User Details</h4></div>
        
        <fieldset disabled="">
            <label className="form-label" htmlFor="disabledInput">Email</label>
            <p><strong>{userProfile.username}</strong></p>
        </fieldset>       
        <fieldset disabled="">
            <label className="form-label" htmlFor="disabledInput">Service</label>
            <p><strong>{userProfile.url}</strong></p>
        </fieldset>
    </div>
    );
}