import { useState } from 'react';
import moment from 'moment'

export default function useToken() {

    const clearToken = () => {
        localStorage.clear()
    }
    

    const getToken = () => {
        const tokenString = localStorage.getItem('token'); 
        if (tokenString == null) {
            return null
        }       

        const payload = JSON.parse(atob(tokenString.split(".")[1]));
        const expiration = moment.unix(payload.exp);

        if(expiration.isBefore()) {
            clearToken();
            return null;
        }

        const userToken = JSON.parse(tokenString);
        return userToken
    };
    const [token, setToken] = useState(getToken());

    const saveToken = (userToken, expiry) => {

        localStorage.setItem('token', JSON.stringify(userToken));
        localStorage.setItem('expiry', expiry);
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token,
        clearToken
    }
}
