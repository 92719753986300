import React, { useState } from "react";
import { FetchAndUpdate } from "./utils";
import useToken from './useToken';

export default function CurrentResponseCode() {

    const [status, setStatus] = useState(200);
    const { token } = useToken();

    FetchAndUpdate('/api/metrics/lastStatus?url=https://localhost', token, setStatus, 30000)

    return (<div className={status >= 200 && status < 400 ? "card text-white bg-success mb-3" : "card text-white bg-danger mb-3"}>
        <div className="card-header">Current Response Code</div>
        <div className="card-body">
            <h4 className="card-title">{status}</h4>
        </div>
    </div>);
}