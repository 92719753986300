import React, { useState } from "react";
import { FetchAndUpdate } from "./utils";
import useToken from './useToken';

export default function CurrentDuration() {
    const [duration, setDuration] = useState(0);
    const { token } = useToken();

    FetchAndUpdate('/api/metrics/lastDuration?url=https://localhost', token, setDuration, 15000)

    return (<div className={duration <= 0.400 ? "card text-white bg-success mb-3" : duration < 0.700 ? "card text-white bg-warning mb-3" : "card text-white bg-danger mb-3"}>
        <div className="card-header">Req Duration(Seconds)</div>
        <div className="card-body">
            <h4 className="card-title">{duration}</h4>
        </div>
    </div>);
}