
import React, { useState, useEffect } from 'react';
import useToken from './useToken';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export default function Verify() {
    return (<div className="row">
        <div className="col-lg-4"></div>
        <div className="col-lg-4"><VerifyPage /></div>
        <div className="col-lg-4"></div>
    </div>);
}

async function sendVerify(credentials) {
    return fetch("/api/users/verify", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

function VerifyPage() {
    const [formError, setFormError] = useState('');
    const { setToken } = useToken();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const verifyCode = searchParams.get("code")
        const result = sendVerify({ verifyCode });
        result.then((resp) => {
            if (resp.error) {
                setFormError("Invalid Token")
                setTimeout(()=>{
                    navigate("/register")
               }, 5000);
            } else if (resp.token) {
                setToken(resp.token, resp.expiry)
                window.location.href = "/";
                //navigate("/");
            }
        });

    }, [navigate, searchParams, setToken])

    return (<div className='bs-docs-section'>
        {formError ? (
            <div className="alert alert-dismissible alert-danger">
                {formError}
            </div>
        ) : ""}
        Your email is being verified
    </div>
    );
}
