export default function Banner() {
    return (<div className="page-header" id="banner">
    <div className="row">
      <div className="col-lg-8 col-md-7 col-sm-6">
        <h1>Site Reliability Status</h1>
        <p className="lead">See the Most Important Reliability Indicators About your Site</p>
      </div>
    </div>
  </div>
    )
}